var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-4 py-2"},[_c('div',{staticClass:"py-4 font-bold text-black border-b border-gray-300 title"},[_vm._v(" 基本信息 ")]),_c('div',{staticClass:"pt-2 content"},[(_vm.userInfo.avatar)?_c('div',{},[_c('div',{staticStyle:{"width":"70px","height":"70px","overflow":"hidden"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":_vm.userInfo.avatar,"alt":""}})])]):_vm._e(),_c('div',{staticClass:"flex items-center py-2 item"},[_c('div',{staticClass:"item_left"},[_vm._v("用户名:")]),(_vm.userInfo.username)?_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.userInfo.username)+" ")]):_vm._e()]),_c('div',{staticClass:"flex items-center py-2 item"},[_c('div',{staticClass:"item_left"},[_vm._v("昵称:")]),(_vm.userInfo.nickname)?_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.userInfo.nickname)+" ")]):_vm._e(),_c('img',{staticStyle:{"margin-left":"10px"},attrs:{"src":require("../../assets/xiugai.svg")},on:{"click":_vm.modifyFunc}})]),_vm._m(0),_c('div',{staticClass:"w-full py-2 text-right"})]),_c('a-modal',{attrs:{"title":"修改信息","visible":_vm.show,"confirm-loading":_vm.confirmLoading,"footer":false},on:{"cancel":function($event){_vm.show = false}}},[_c('div',[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"昵称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'nickname',
              {
                rules: [{ required: true, message: '请输入标签名称' }],
              },
            ]),expression:"[\n              'nickname',\n              {\n                rules: [{ required: true, message: '请输入标签名称' }],\n              },\n            ]"}],attrs:{"placeholder":"请输入昵称"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [{}],
              },
            ]),expression:"[\n              'password',\n              {\n                rules: [{}],\n              },\n            ]"}],attrs:{"placeholder":"请输入密码"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formTailLayout.labelCol,"wrapper-col":_vm.formTailLayout.wrapperCol}},[_c('a-button',{staticStyle:{"width":"200px"},attrs:{"type":"","size":"large"},on:{"click":_vm.myEditFunc}},[_vm._v(" 编辑 ")])],1)],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center py-2 item"},[_c('div',{staticClass:"item_left"})])
}]

export { render, staticRenderFns }